.loadingSpinner {
    animation: ht-loading-spinner 1s linear infinite;
    margin-right: 4px;
}

@keyframes :local(ht-loading-spinner) {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
